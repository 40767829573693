.SearchForm {
    display: flex;
    align-items: center;
    justify-content: center;
    /* background-color: #a892ee; */
    padding: 1rem;
    margin: 2rem auto;
    width: 50rem;
    max-width: 95%;
    border-radius: 12px;
    text-align: center;
    /* box-shadow: 0 1px 8px rgba(0, 0, 0, 0.25); */
}

.SearchForm button {
    font: inherit;
    cursor: pointer;
    padding: 0.5rem 0.5rem;
    border: 1px solid #7FFFD4;
    /* background-color: #40005d; */
    color: white;
    border-radius: 12px;
    margin-right: 1rem;


}

.SearchForm button:hover,
.SearchForm button:active {
    background-color: #510674;
    border-color: #510674;
}

.SearchForm button.alternative {
    color: #220131;
    border-color: transparent;
    background-color: transparent;
}

.SearchForm button.alternative:hover,
.SearchForm button.alternative:active {
    background-color: #ddb3f8;
}

.SearchForm__controls {
    display: flex;
    align-items: center;
    justify-items: center;
    flex-wrap: wrap;
    gap: 1rem;
    margin-bottom: 1rem;
    text-align: left;
}

.SearchForm__control label {
    font-weight: bold;
    margin-bottom: 0.5rem;
    display: block;
}

.SearchForm__control input {
    font: inherit;
    padding: 0.5rem;
    border-radius: 6px;
    border: 1px solid #7FFFD4;
    width: 20rem;
    max-width: 100%;
}

.SearchForm__actions {
    bottom: 0%;
}

