.Loading {
    display: flex;
    align-items: center;
    justify-content: center;
    /* border: 1px solid #7FFFD4; */
    padding: 1rem;
    margin: 2rem auto;
    width: 50rem;
    max-width: 95%;
    border-radius: 12px;
    text-align: center;
    color: #faebd7;
}