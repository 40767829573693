.UserAuth {
    display: flex;
    justify-content: right;
    /* background-color: #a892ee; */
    padding: 0rem;
    margin: 0.5rem auto;
    width: auto;
    max-width: 99%;
    border-radius: 12px;
    /* box-shadow: 0 1px 8px rgba(0, 0, 0, 0.25); */
}

.UserAuth button {
    font: inherit;
    cursor: pointer;
    padding: 0.5rem 0.5rem;
    border: 1px solid #7FFFD4;
    /* background-color: #40005d; */
    color: rgb(140, 140, 140);
    border-radius: 12px;
    margin-right: 1rem;
}

.UserAuth button:hover,
.UserAuth button:active {
    background-color: #510674;
    border-color: #510674;
}

.UserAuth button.alternative {
    color: #220131;
    border-color: transparent;
    background-color: transparent;
}

.UserAuth button.alternative:hover,
.UserAuth button.alternative:active {
    background-color: #ddb3f8;
}

.UserAuth p {
    font-weight: bold;
    color: #faebd7;
    margin-bottom: 1rem;
    margin-right: 0.5rem;
}

