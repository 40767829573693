.Card__container {
  position: relative;
}
.Card {
  border: 1px solid #7FFFD4;
  /* margin: 12px; */
  /* The following makes the element align center horizontally */
  margin: 12px auto; 
  border-radius: 4px;
  width: 80%;
  background-color: #fafbfd;
  box-shadow: 1px 2px 5px 3px rgba(0, 0, 0, 0.1);
  padding: 4px 2px;
}

.Card__subject {
  display: flex;
  margin: 0 8px 4px;
  border-bottom: 1px solid #333;
  text-align: left;
  padding: 4px 8px;
}

.Card__title {
  font-size: 18px;
}

.Card__description {
  word-wrap: break-word;
  padding: 8px;
}

.Card__misc-wrapper {
  display: flex;
}

.Card__misc {
  /* display: flex; */
  font-size: 12px;
  padding: 8px;
}
.Card__widget {
  font-size: 12px;
  padding: 2px;
}

.Card__widget button {
  font: inherit;
  cursor: pointer;
  padding: 0.5rem 0.5rem;
  border: 1px solid #7FFFD4;
  /* background-color: #40005d; */
  color: rgb(140, 140, 140);
  border-radius: 12px;
  margin-right: 1rem;
}